import { AdvertData } from './adEventHelpers';

// We want to track the ad events on the Resene logos.
// But we don't have related Advert and Placement for the Resene logos.
// So we create a dummy Advert for the Resene logos.
export const getReseneLogoAdEventData = (): AdvertData => {
    // Hardcode Ad data for the Resene logos
    return {
        id: 266, // AdvertCreativeID for the Resene logos
        professional: {
            id: 25659, // Resene professional ID
        },
    };
};
